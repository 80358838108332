
import React from 'react';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"


import Layout from '../../../components/layout/Layoutexport';
import CardProductsExport from '../../../components/CardProductsExport'





import { Link } from 'gatsby';






const seo={
  title: "Productos para la industria alimenticia para exportacion",
  desc:  "Fabricamos y comercializamos productos para la region y el mundo",
  image: "productos.jpg",
  pathname: "/export/es/industria-alimenticia/",
  alternateEn: "/export/en/food-industry/",
  alternateEs: "/export/es/industria-alimenticia/",
  alternateEsec: "/industria-alimenticia/",
  alternateDefault: "/export/es/industria-alimenticia/"
}

const siteVerOpts = [

  {
    label: 'Internacional',
    value: '',

  },


  {
    label: 'Ecuador',
    value: '/industria-alimenticia/',

  },


];

const langOpts = [

  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: '/export/en/food-industry/',

  },


];

const CategorieExport = ({location, lang = "es", data}) => {






  return(

          <Layout location={location} crumbLabel="Productos para la industria alimenticia para exportacion "
            siteVerOpts={siteVerOpts} langOpts={langOpts}
            alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
            title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

<section id="heading-banner" className={`mt-2 mb-0 mr-0 border-0 p-0 bg-blue-900`}>
                    <div className="flex flex-row">
                          <div className="grow border-0">
                              <h1 className={`mt-8 ml-2 text-base font-semibold leading-tight tracking-wider py-10
                                              sm:mt-6 sm:ml-6 sm:text-2xl 
                                              md:mt-6 md:ml-12 md:text-2xl md:tracking-wider md:leading-snug
                                              lg:mt-8 lg:text-3xl lg:tracking-wide lg:leading-snug 
                                              xl:mt-8 xl:text-5xl xl:tracking-wider
                                              text-left text-white
                                              `}>
                              Industria Alimenticia
                              </h1>

                          </div>
                          <div className="h-44 lg:h-96 border-0">

                            <StaticImage src="../../../images/banner-industria-alimenticia.png"
                                            className="object-contain h-full"
                                            imgClassName=""


                                            alt="Categoria de productos: Industria Alimenticia"
                                            placeholder="blurred"
                                            layout="constrained"

                            />
                          </div>


                    </div>
      </section>

      <section id="intro-paragraph">
              <div className="w-full mx-auto mt-0 mb-12 px-2 bg-gray-200">
                <div className="p-6 lg:pt-12 lg:pb-36">
                  <h2 className="my-6 lg:my-8 text-primary-default text-center text-2xl md:text-3xl font-semibold">Productos de higiene profesional para la industria alimenticia</h2>
                  <div className="m-0 sm:m-4 lg:w-5/6 lg:mx-auto">
                    <p className="text-2xl font-normal leading-relaxed  text-primary-default mt-6 mb-8  text-md md:text-lg lg:text-xl text-justify">
                      <StaticImage src="../../../images/soluciones-industria-alimenticia.jpg"
                                  className={`

                                    mt-2 mb-8 float-none w-full
                                    sm:mt-6 sm:ml-6 sm:mr-0 sm:float-right sm:w-72 sm:mb-4
                                    md:ml-10 md:mb-4
                                    lg:mt-6 lg:w-96 lg:h-48
                                    shadow-md

                                  `}

                                  imgClassName=""
                                  alt="Productos de alta calidad para la industria alimenticia"
                                  placeholder="blurred"
                                  layout="constrained" /><br/>
                        Sabemos que necesita un proceso de inocuidad alimentaria eficiente en su empresa y para sus clientes, por eso somos su mejor aliado, contamos con los productos de higiene profesional y asesorías adecuadas que le garantizan la implementación de procedimientos de limpieza prácticos y sencillos que le apoyan en su operación. En Unilimpio encuentra las mejores soluciones de higiene de acuerdo a los más altos estándares de calidad del mercado.
                    </p>
                  </div>

                </div>
              </div>
</section>

<section id="grid-productos" className="my-12">
    <h3 className="my-6 md:my-8 text-center mx-auto text-primary-lighter text-2xl font-semibold">- Descubra nuestros productos de higiene profesional para la industria alimenticia -</h3>
    <div className=" bg-white px-4 sm:px-6 lg:pt-6 lg:pb-28 lg:px-8">
     <div className="relative max-w-7xl mx-auto">

      <div className="relative max-w-lg mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">



      {data.allProducts2CategoryesJson.edges.map( ({node}) => (
        <div key={node.id} className="flex flex-col overflow-hidden hover:bg-zinc-100 rounded-lg hover:shadow-md border">
          <Link to={`/export/${lang}/${node.slug}/`} className="text-lg font-semibold text-center text-primary-lighter hover:text-zinc-500 hover:no-underline">
          <CardProductsExport className="p-6">

              <div className="flex-1">
                <GatsbyImage image={getImage(node.featuredImage)}
                        className="flex justify-center my-4 mx-4"
                        alt={node.title}
                 />
                      <div className="flex flex-wrap mx-auto my-4 justify-center">
                       {node.title}
                     </div>

              </div>

          </CardProductsExport>
          </Link>
        </div>
      ))}

{/*
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/producto-jabon-spray-sachet.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                  <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/jabon-spray-sachet/" className="text-xl font-bold text-secondary-lighter text-center"> </Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/jabon-spray-sachet/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/producto-jabon-espuma-sachet.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                  <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/jabon-espuma-sachet/" className="text-xl font-bold text-secondary-lighter text-center">Jabón Espuma en Sachet para Dispensador</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/jabon-espuma-sachet/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/producto-gel-abtibacterial-sachet.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                 <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/gel-antibacterial-sachet/" className="text-xl font-bold text-secondary-lighter text-center">Gel Antibacterial en Sachet para Dispensador</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/gel-antibacterial-sachet/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/producto-alcohol-sachet.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                 <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/alcohol-antimicrobiano-sachet/" className="text-xl font-bold text-secondary-lighter text-center">Alcohol Antimicrobiano en Sachet para Dispensador</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/alcohol-antimicrobiano-sachet/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/categoria-gel-antibacterial.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                  <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/gel-antibacterial/" className="text-xl font-bold text-secondary-lighter text-center">Gel Antibacterial</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/gel-antibacterial/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
            <StaticImage src="../../../images/categoria-jabon-liquido.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained"/>
                  <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/jabon-liquido-antibacterial/" className="text-xl font-bold text-secondary-lighter text-center">Jabón Líquido Antibacterial</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/jabon-liquido-antibacterial/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex-1 bg-white p-6 flex flex-col justify-between">
          <div className="flex-1">
           <StaticImage src="../../../images/producto-jabon-espuma.jpg"
                    className="flex justify-center my-4 mx-4"
                    alt="Unilimpio es una empresa con responsabilidad social empresarial."
                    placeholder="blurred"
                    layout="constrained" />
                  <div className="flex flex-wrap mx-auto justify-center">
                   <Link to="/export/es/jabon-espuma/" className="text-xl font-bold text-secondary-lighter text-center">Jabón Espuma</Link>
                 </div>
              <div className="mt-4 ">
                <Link to="/export/es/jabon-espuma/"  className=" ">
                    <Button className="flex mx-auto text-white bg-primary-lighter border-0 py-2 px-8 rounded-full transition duration-200 ease-in-out bg-primary-lighter shadow-md hover:bg-primary-xlighter  transform hover:-translate-y-1 hover:scale-105 focus:outline-none" >Ver producto </Button>
                </Link>
              </div>
          </div>
        </div>
      </div>
*/}

     </div>
    </div>
  </div>
</section>


          </Layout>

        );
}

export const query = graphql`
  query CategoryIndustriaAlimenticiaExportQuery {
    allProducts2CategoryesJson(filter: {categorySlug: {eq: "industria-alimenticia"}}) {
      edges {
        node {
          featuredImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
          id
          slug
          title
        }
      }
    }
  }
`

export default CategorieExport;
